<!--
  PACKAGE_NAME : src\pages\ewm\demo\default-dx-component-test.vue
  FILE_NAME : modal-agent-info-test
  AUTHOR : bykim
  DATE : 2023-12-05
  DESCRIPTION : 컴포넌트 데모 페이지
-->
<template>
  <div class="page-sub-box">
    <div>
      <esp-dx-data-grid :data-grid="dataGrid" ref="demoGrid" @row-click="onRowClick" />
      <modal-add-agent
        :isOpen="popupOptions.visible"
        :showModalTitle="popupOptions.showModalTitle"
        :selectedIdList="selectedIdList"
        @closeModal="onClose(false)"
        @saveModal="onSave"
        :title="popupOptions.title"
        :width="popupOptions.width"
        :height="popupOptions.height"
        :minWidth="popupOptions.minWidth"
        :minHeight="popupOptions.minHeight"
        :useSaveBtn="popupOptions.useSaveBtn"
        :useCancelBtn="popupOptions.useCancelBtn"
        :saveBtnTxt="popupOptions.saveBtnTxt"
        :cancelBtnTxt="popupOptions.cancelBtnTxt"
        :minimumSelectionLength="popupOptions.minimumSelectionLength"
        :maximumSelectionLength="popupOptions.maximumSelectionLength"
      />
    </div>
  </div>
</template>

<script>
  import DxButton from 'devextreme-vue/button';
  import ModalAddAgent from '@/components/ewm/hr/modal-add-agent.vue';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: { EspDxDataGrid, DxButton, ModalAddAgent },
    data() {
      return {
        popupOptions: {
          showModalTitle: true,
          title: '(옵션 설정 데모) 상담원 정보 모달 팝업',
          width: 950,
          height: 600,
          minWidth: 850,
          minHeight: 600,
          visible: false,
          useSaveBtn: true,
          useCancelBtn: true,
          saveBtnTxt: '선택',
          cancelBtnTxt: '취소',
          minimumSelectionLength: 2,
          maximumSelectionLength: 3,
        },
        selectedIdList: [],
        dataGrid: {
          keyExpr: 'hrId', // 그리드 키값
          refName: 'demoGrid', // 그리드 컴포넌트 참조명
          callApi: 'CALL_EWM_API', // 그리드 데이터 호출 API
          excel: {
            title: '엑셀파일명', // 엑셀 다운로드 시 파일명
            autoFilterEnabled: true, // 엑셀 필터 사용 유무
          },
          columnResizingMode: 'widget', // 컬럼 사이즈 조절 모드 : ['nextColumn', 'widget']
          columnAutoWidth: false, // 컬럼 사이즈 자동 조절 유무
          focusedRowEnabled: true, // 포커스 행 표시 유무
          allowColumnResizing: true, // 컬럼 사이즈 조절 유무
          showBorders: false, // 그리드 테두리 유무
          showColumnHeaders: true, // 컬럼 헤더 유무
          showColumnLines: true, // 컬럼 세로선 유무
          showRowLines: true, // 컬럼 가로선 유무
          rowAlternationEnabled: false, //행 배경색 교차 유무
          dataSource: [], // 그리드 데이터
          // width:'200',   // 주석처리시 100%
          // height: '620', // 주석처리시 100%
          apiActionNm: {}, // api 호출시 사용할 액션명(ESP - API URL 관리)
          scrolling: {
            mode: 'standard', // 스크롤 모드 : ['standard', 'virtual', 'infinite']
          },
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            cellPrepared: true, // 셀 이벤트
            rowClick: true, // 행 클릭 이벤트
          },
          showActionButtons: {
            excel: true, // 엑셀 다운로드 버튼
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '인사모달',
                  elementAttr: { class: 'btn_XS default filled add1' },
                  width: 100,
                  height: 30,
                  onClick: () => {
                    this.onOpen();
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '상담사선택',
                  elementAttr: { class: 'btn_XS white light_filled' },
                  width: 100,
                  height: 30,
                  onClick: () => {
                    this.onSelAgtid();
                  },
                },
                location: 'before',
              },
            ], // 그리드 커스텀 버튼 생성
          },
          isDuplicateConfigKey: false, // 설정키 중복 체크
          grouping: {
            contextMenuEnabled: false, // 그룹핑 컨텍스트 메뉴 사용유무
            autoExpandAll: false, // 그룹핑시 전체 펼침 여부
            allowCollapsing: true, // 그룹핑시 접기 허용 여부
            expandMode: 'rowClick', // 그룹핑 펼침 모드 : ['rowClick', 'buttonClick']
          },
          groupPanel: {
            visible: false, // 그룹패널 표시 여부
          },
          columnChooser: {
            enabled: false, // 컬럼 선택 팝업 표시 여부
          },
          loadPanel: {
            enabled: false, // 로딩 패널 표시 여부
          },
          sorting: {
            mode: 'multiple', // 정렬 모드 : ['none', 'single', 'multiple']
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false, // 페이징 사용 유무
            // pageSize: 10,
            // pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, // 페이지 사이즈 선택 버튼 표시 여부
            allowedPageSizes: [], // 페이지 사이즈 선택 버튼에 표시할 항목
            displayMode: 'compact', // 페이저 표시 모드 : ['adaptive', 'compact', 'full']
            showInfo: false, // 페이지 정보 표시 여부
            showNavigationButtons: false, // 페이지 이동 버튼 표시 여부
          },
          filterRow: {
            visible: false, // 필터 행 표시 여부
          },
          headerFilter: {
            visible: false, // 헤더 필터 표시 여부
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false, // 삭제 버튼을 없애고 싶으면 false설정(Row 마다 휴지통 생성)
            mode: 'batch', // 행 편집 모드 : ['cell', 'row', 'batch']
            startEditAction: 'click', // 행 편집 시작 액션 : ['click', 'dblClick']
            selectTextOnEditStart: false, // 편집 시작시 텍스트 선택 여부
          },
          selecting: {
            mode: 'multiple', // 행 선택 모드 : ['none', 'single', 'multiple']
            selectAllMode: 'allPages', // 전체 선택 모드 : ['page', 'allPages']
            showCheckBoxesMode: 'always', // 체크박스 표시 모드 : ['none', 'onClick', 'onLongTap', 'always']
            deferred: false, // 선택 지연 여부
          },
          columns: [
            {
              caption: 'U_HR_USER_MASTER.ID',
              dataField: 'hrId',
              visible: true,
              sortOrder: 'none',
            },
            {
              caption: '부서코드',
              dataField: 'deptCd',
              visible: true,
              sortOrder: 'none',
            },
            {
              caption: '전체부서코드',
              dataField: 'deptCdPath',
              visible: true,
              sortOrder: 'none',
            },
            {
              caption: '전체부서코드명',
              dataField: 'deptNmPath',
              visible: true,
              sortOrder: 'none',
            },
            {
              caption: '상담원ID',
              dataField: 'agtid',
              visible: true,
              sortOrder: 'none',
            },
            {
              caption: '상담원명',
              dataField: 'agtNm',
              visible: true,
              sortOrder: 'none',
            },
            {
              caption: '내선번호',
              dataField: 'agtdn',
              visible: true,
              sortOrder: 'none',
            },
            {
              caption: 'authId',
              dataField: 'authId',
              visible: true,
              sortOrder: 'none',
            },
            {
              caption: '직급코드',
              dataField: 'jikgupCd',
              visible: true,
              sortOrder: 'none',
            },
            {
              caption: '직급명',
              dataField: 'jikgupNm',
              visible: true,
              sortOrder: 'none',
            },
            {
              caption: '직위코드',
              dataField: 'jikweeCd',
              visible: true,
              sortOrder: 'none',
            },
            {
              caption: '직위명',
              dataField: 'jikweeNm',
              visible: true,
              sortOrder: 'none',
            },
            {
              caption: '일사일자',
              dataField: 'joinDt',
              visible: true,
              sortOrder: 'none',
            },
            {
              caption: '퇴사일자',
              dataField: 'retireDt',
              visible: true,
              sortOrder: 'none',
            },
            {
              caption: '유저상태코드',
              dataField: 'userStateCd',
              visible: true,
              sortOrder: 'none',
            },
            {
              caption: '업무그룹코드',
              dataField: 'workgroupCd',
              visible: true,
              sortOrder: 'none',
            },
            {
              caption: '업무그룹명',
              dataField: 'workgroupNm',
              visible: true,
              sortOrder: 'none',
            },
            {
              caption: '사용여부',
              dataField: 'viewFl',
              visible: true,
              sortOrder: 'none',
            },
            {
              caption: '삭제여부',
              dataField: 'delFl',
              visible: true,
              sortOrder: 'none',
            },
            {
              caption: 'CTI ID',
              dataField: 'ctiid',
              visible: true,
              sortOrder: 'none',
            },
            {
              caption: 'CTI 사용여부',
              dataField: 'ctiUseFl',
              visible: true,
              sortOrder: 'none',
            },
          ], // 컬럼 정보
        },
      };
    },
    computed: {},
    methods: {
      onOpen() {
        this.popupOptions.visible = true;
        this.$_Msg(
          `최소 선택 개수 ${this.popupOptions.minimumSelectionLength}개 이상 </br>
        최대 선택 개수 ${this.popupOptions.maximumSelectionLength}개 이하`,
          { title: '상담원 팝업 옵션', type: 'info', allowOutsideClick: true },
        );
      },
      onClose() {
        this.popupOptions.visible = false;
        this.selectedIdList = [];
      },
      onSave(selDataList) {
        this.popupOptions.visible = false;
        this.selectedIdList = [];
        this.dataGrid.dataSource = selDataList;
      },
      onSelAgtid() {
        this.selectedIdList = ['bykim', 'orange', 'bjy'];
      },
      onRowClick(e) {
        console.log(e);
      },
    },
    created() {},
    mounted() {},
  };
</script>

<style lang="scss" scoped></style>
